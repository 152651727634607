import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import quoteImage from '../../images/quote-marks.svg'
import { H3, H6 } from '@system'
import classNames from 'classnames'
import { ContentContainer } from '@system'
import renderBloks from '@renderBloks'
import MarkdownHandler from '@system/markdown'

const useStyles = makeStyles((theme) => ({
  background: ({ profileVariant, quoteCarouselVariant }) => ({
    background: profileVariant
      ? theme.palette.background.primary
      : theme.palette.gradient.background,
    paddingBottom: quoteCarouselVariant ? '180px' : '128px',
    paddingTop: '128px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: quoteCarouselVariant ? '116px' : '64px',
      paddingTop: '64px',
    },
  }),
  headlineBackground: {
    backgroundColor: theme.palette.background.darkGrey,
  },
  headline: {
    color: theme.palette.common.white,
    fontSize: '1.5rem',
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
  },
  image: {
    objectFit: 'contain',
    width: 90,
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      width: 70,
      marginBottom: '1.5rem',
    },
  },
  quote: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightLight,
    textAlign: 'center',
    display: 'block',
  },
  headlineVariantQuote: {
    fontSize: '1.9rem',
    maxWidth: '75%',
    margin: '2rem auto 3rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem',
    },
  },
  profileVariantQuote: {
    color: theme.palette.text.primary,
    fontSize: '28px',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '24px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      textAlign: 'center',
    },
  },
  authorContainer: ({ profileVariant }) => ({
    display: !profileVariant && 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: profileVariant && '220px',
    height: profileVariant && '220px',
    [theme.breakpoints.up('md')]: {
      marginRight: profileVariant && '32px',
    },
  }),
  imageContainer: ({ profileVariant }) => ({
    marginRight: !profileVariant && '1.5rem',
    width: profileVariant ? '100%' : '75px',
    '& .gatsby-image-wrapper': {
      borderRadius: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: !!profileVariant && '0',
    },
  }),
  companyLogoimageContainer: ({ profileVariant }) => ({
    height: profileVariant && '56px',
    margin: !profileVariant && '0.5rem 0',
    maxHeight: !profileVariant && '33px',
    '& > div': {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: profileVariant && '16px 0',
      order: 3,
    },
  }),
  authorName: {
    fontWeight: theme.typography.fontWeightBold,
  },
  authorNameProfileVariant: {
    color: theme.palette.text.primary,
    textAlign: 'right',
    '& p': {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  attribution: ({ isHeadlineVariant }) => ({
    color: theme.palette.common.white,
    textAlign: isHeadlineVariant ? 'left' : 'center',
    fontWeight:
      theme.typography[
        isHeadlineVariant ? 'fontWeightLight' : 'fontWeightBold'
      ],
    display: 'flex',
    flexDirection: 'column',
  }),
  removeTopSpacing: {
    paddingTop: '0px',
  },
  outerContainer: ({ profileVariant }) => ({
    display: !!profileVariant && 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: !!profileVariant && 'column',
      alignItems: !!profileVariant && 'center',
    },
  }),
  profileVariantQuoteContainer: {
    display: 'flex',
    flexDirection: 'column',
    order: '2',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      width: '100%',
    },
  },
  profileVariantAuthorLogo: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  companyLogoMarkdown: {
    fontWeight: theme.typography.fontWeightLight,
  },
}))

const QuoteModule = ({ blok, quoteCarouselVariant }) => {
  const {
    headline,
    quote,
    attribution,
    authorName,
    authorImage,
    variant,
    removeTopSpacing,
    companyLogo,
  } = blok
  const parsedVariant = quoteCarouselVariant || variant
  const isHeadlineVariant = parsedVariant === 'headline'
  const profileVariant = parsedVariant === 'profileVariant'

  const classes = useStyles({
    isHeadlineVariant,
    profileVariant,
    quoteCarouselVariant,
  })

  return (
    <SbEditable content={blok}>
      <Box
        className={classNames(classes.background, {
          [classes.removeTopSpacing]: removeTopSpacing,
          [classes.headlineBackground]: isHeadlineVariant,
        })}
      >
        <ContentContainer className={classes.outerContainer}>
          {(isHeadlineVariant || profileVariant) && !!headline && (
            <Box display="flex" justifyContent="center">
              <H3 className={classes.headline}>{headline}</H3>
            </Box>
          )}
          {!isHeadlineVariant && !profileVariant && !!quoteImage && (
            <Box display="flex" justifyContent="center">
              <img
                src={quoteImage}
                className={classes.image}
                alt="Quotation mark"
              />
            </Box>
          )}
          <Box
            className={classNames({
              [classes.profileVariantQuoteContainer]: profileVariant,
            })}
          >
            <H3
              className={classNames(classes.quote, {
                [classes.headlineVariantQuote]: isHeadlineVariant,
                [classes.profileVariantQuote]: profileVariant,
              })}
            >
              {(!isHeadlineVariant || !profileVariant) && '"'}
              {quote?.replace(/^"(.*)"$/, '$1')}
              {(!isHeadlineVariant || !profileVariant) && '"'}
            </H3>
            <Box className={classes.profileVariantAuthorLogo}>
              {!!companyLogo?.length && profileVariant && (
                <Box className={classes.companyLogoimageContainer}>
                  {renderBloks(companyLogo)}
                </Box>
              )}
              <H6 className={classes.companyLogoH6}>
                {profileVariant && authorName && (
                  <span className={classes.authorNameProfileVariant}>
                    <MarkdownHandler className={classes.companyLogoMarkdown}>
                      {authorName}
                    </MarkdownHandler>
                  </span>
                )}
              </H6>
            </Box>
          </Box>
          {
            <Box
              className={classNames({
                [classes.authorContainer]: isHeadlineVariant || profileVariant,
              })}
            >
              {!!authorImage?.length &&
                (isHeadlineVariant || profileVariant) && (
                  <Box className={classes.imageContainer}>
                    {renderBloks(authorImage)}
                  </Box>
                )}
              <H6 className={classes.attribution}>
                {isHeadlineVariant && authorName && (
                  <span className={classes.authorName}>{authorName}</span>
                )}
                {!isHeadlineVariant || !profileVariant ? '- ' : ''}
                {attribution}
              </H6>
            </Box>
          }
        </ContentContainer>
      </Box>
    </SbEditable>
  )
}
export default QuoteModule
